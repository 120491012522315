import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";

/**
 * 스케줄 이력 GET
 * @param {*} sheduleId 
 * @returns 
 */
export function fetchScheduleHistory(params) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/schedule/item/log/list`, {
        params,
        headers,
    });
}


/**
 * 스케줄 이력디테일 GET
 * @param {*} sheduleId 
 * @returns 
 */
export function fetchScheduleHistoryDetail(scheduleItemLogId) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/schedule/item/log/${scheduleItemLogId}`, {
        headers,
    });
}
